import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'react-bootstrap';
import Icon from 'components/Icon';

const FareHarborItemsLink = ({label, iconName, itemId}) => {
    return (
        <Button variant="secondary" size="sm" style={{whiteSpace: 'nowrap'}} onClick={() => {
            window.open(
                `https://fareharbor.com/embeds/book/wedive/items/?flow=${itemId}&full-items=yes`,
                '_blank'
            );
        }}>
            <Icon iconName={iconName}/> {label}
        </Button>
    );
};

FareHarborItemsLink.propTypes = {
    label: PropTypes.string,
    iconName: PropTypes.string,
    itemId: PropTypes.string.isRequired,
};

FareHarborItemsLink.defaultProps = {
    label: 'book',
    iconName: 'ShoppingCartIcon'
};

export default FareHarborItemsLink;
