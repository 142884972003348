import React from 'react';
import PropTypes from 'prop-types';

import CircleIcon from 'components/CircleIcon';

const TripAdvisor = ({attraction}) => (
    <CircleIcon href={`https://www.tripadvisor.de/${attraction}`} iconName="TripAdvisorIcon"/>
);

TripAdvisor.propTypes = {
    attraction: PropTypes.string.isRequired,
};

export default TripAdvisor;
