import makeFAIcon from 'utils/makeFAIcon';

import {
    faPhone,
    faEnvelope,
    faMapMarkerAlt,
    faMapMarkedAlt,
    faPlus,
    faBars,
    faTimes,
    faGlobe,
    faUserGraduate,
    faWater,
    faInfo,
    faGraduationCap,
    faSearch,
} from '@fortawesome/free-solid-svg-icons';
import {
    faTwitter,
    faFacebookF,
    faLinkedinIn,
    faGithubAlt,
    faMediumM,
    faInstagram,
    faYoutube,
    faTripadvisor,
} from '@fortawesome/free-brands-svg-icons';

export const InfoIcon = makeFAIcon(faInfo);
export const GraduationCap = makeFAIcon(faGraduationCap);
export const PhoneIcon = makeFAIcon(faPhone);
export const EnvelopIcon = makeFAIcon(faEnvelope);
export const MapMarkerIcon = makeFAIcon(faMapMarkerAlt);
export const MapMarkedIcon = makeFAIcon(faMapMarkedAlt);
export const PlusIcon = makeFAIcon(faPlus);
export const SearchIcon = makeFAIcon(faSearch);
export const BarsIcon = makeFAIcon(faBars);
export const GithubIcon = makeFAIcon(faGithubAlt);
export const MediumIcon = makeFAIcon(faMediumM);
export const CloseIcon = makeFAIcon(faTimes);
export const LanguageIcon = makeFAIcon(faGlobe);
export const StudyIcon = makeFAIcon(faUserGraduate);
export const WaterIcon = makeFAIcon(faWater);

export const TwitterIcon = makeFAIcon(faTwitter);
export const FacebookIcon = makeFAIcon(faFacebookF);
export const LinkedinIcon = makeFAIcon(faLinkedinIn);
export const InstagramIcon = makeFAIcon(faInstagram);
export const YouTubeIcon = makeFAIcon(faYoutube);
export const TripAdvisorIcon = makeFAIcon(faTripadvisor);

export * from 'config/CustomIcons';
