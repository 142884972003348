import React from 'react';
import PropTypes from 'prop-types';

import {Modal, Button} from 'react-bootstrap';

import './HotNews.scss';

const HotNews = ({
                     header,
                     content,
                     buttonClose
                 }) => {

    const [showHotNews, setShowHotNews] = React.useState(false);
    const handleShowHotNews = React.useCallback(() => {
        setShowHotNews(true);
    }, []);
    const handleHideHotNews = React.useCallback(() => {
        setShowHotNews(false);
    }, []);

    return (
        <>
            <a className="hotnews" href="#!" data-toggle="modal" onClick={handleShowHotNews}>{header}</a>
            <Modal
                show={showHotNews}
                onHide={handleHideHotNews}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">{header}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="mx-auto" dangerouslySetInnerHTML={{__html: content}}/>
                <Modal.Footer>
                    <div className="mx-auto">
                        <Button variant="primary" onClick={handleHideHotNews}>{buttonClose}</Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
};

HotNews.propTypes = {
    header: PropTypes.string,
    content: PropTypes.string,
    buttonClose: PropTypes.string,
};

HotNews.defaultProps = {
    header: 'Hot News',
    content: 'foo',
    buttonClose: 'bar',
};

export default HotNews;
