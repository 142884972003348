import React from 'react';
import PropTypes from 'prop-types';

import {Row, Col} from 'react-bootstrap';
import SpotItem from 'components/SpotItem';
import SectionHeader from 'components/SectionHeader';
import PageSection from 'components/PageSection';
import FareHarborItemsLink from 'components/FareHarborItemsLink';
import nl2br from 'utils/nl2br';

import './Spots.scss';

const Spots = ({className, frontmatter, globals}) => {
    if (!frontmatter) {
        return null;
    }

    const {anchor, header: rootHeader, subheader: rootSubHeader, spots} = frontmatter;

    return (
        <PageSection className={className} id={anchor}>
            <Row>
                <SectionHeader header={rootHeader} subheader={rootSubHeader}>
                    <FareHarborItemsLink itemId={463355} label={globals.buttonBookDives}/>
                </SectionHeader>
            </Row>
            <Row>
                <Col lg={12}>
                    <ul className="spots">
                        {spots.map(({content, header, imageContent, imageFileName, subheader, maxDepth, wrecks}, ind) => (
                            <SpotItem
                                invert={ind % 2 === 1}
                                key={header}
                                imageFileName={imageFileName}
                                header={header}
                                subheader={subheader}
                                content={content}
                                maxDepth={maxDepth}
                                depthLabel={globals.depth}
                                meterLabel={globals.meter}
                                imageContent={
                                    imageContent ? (
                                        <div style={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                                             dangerouslySetInnerHTML={{__html: `<h4 style="margin:0;">${nl2br(imageContent)}</h4>`}}/>
                                    ) : null
                                }
                                wrecks={wrecks}
                            />
                        ))}
                    </ul>
                </Col>
            </Row>
        </PageSection>
    );
};

Spots.propTypes = {
    className: PropTypes.string,
    frontmatter: PropTypes.object,
    globals: PropTypes.object,
};

Spots.defaultProps = {
    className: null,
    frontmatter: null,
    globals: null,
};

export default Spots;
