import React from 'react';
import PropTypes from 'prop-types';

import {Col} from 'react-bootstrap';
import Image from 'components/Image';
import Icon from 'components/Icon';
import CourseDetailDialog from 'components/CourseDetailDialog';

import './CourseItem.scss';

const CourseItem = ({
                        imageFileName,
                        imageAlt,
                        header,
                        subheader,
                        content,
                        imageFileNameDetail,
                        imageAltDetail,
                        courseData,
                        globals,
                        location
                    }) => {
    const slug = header.toLowerCase().replace(/ /g, '-')
        .replace(/[^\w-]+/g, '');
    const [showDetail, setShowDetail] = React.useState(location && location.hash && location.hash === `#${slug}`);

    const handleShowDetail = React.useCallback(() => {
        setShowDetail(true);
    }, []);

    const handleHideDetail = React.useCallback(() => {
        setShowDetail(false);
    }, []);

    return (
        <>
            <Col md={4} sm={6} className="course-item">
                <a
                    href={`#${slug}`}
                    role="button"
                    tabIndex={-1}
                    className="course-link"
                    data-toggle="modal"
                    onClick={handleShowDetail}
                >
                    <Image
                        className="img-fluid"
                        fileName={imageFileName}
                        alt={imageAlt || header || subheader}
                    />
                    <div className="course-hover">
                        <div className="course-hover-content">
                            <Icon iconName="SearchIcon" size="2x"/>
                        </div>
                    </div>
                </a>
                <div className="course-caption">
                    <h4>{header}</h4>
                    {subheader ? <p className="text-muted">{subheader}</p> : null}
                </div>
            </Col>
            <CourseDetailDialog
                show={showDetail}
                onHide={handleHideDetail}
                imageFileName={imageFileNameDetail || imageFileName}
                imageAlt={imageAltDetail || imageAlt}
                header={header}
                subheader={subheader}
                content={content}
                courseData={courseData}
                globals={globals}
            />
        </>
    );
};

CourseItem.propTypes = {
    imageFileName: PropTypes.string.isRequired,
    imageAlt: PropTypes.string,
    header: PropTypes.string.isRequired,
    subheader: PropTypes.string,
    content: PropTypes.string,
    imageFileNameDetail: PropTypes.string,
    imageAltDetail: PropTypes.string,
    courseData: PropTypes.any,
    globals: PropTypes.object,
    location: PropTypes.object,
};

CourseItem.defaultProps = {
    imageAlt: '',
    subheader: '',
    content: '',
    imageFileNameDetail: '',
    imageAltDetail: '',
    courseData: null,
    globals: null,
    location: null,
};

export default CourseItem;
