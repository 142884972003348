import React from 'react';
import PropTypes from 'prop-types';

import {Row, Col} from 'react-bootstrap';

import ServiceItem from 'components/ServiceItem';
import PageSection from 'components/PageSection';

const Services = ({className, frontmatter}) => {
    if (!frontmatter) {
        return null;
    }

    const {anchor, services} = frontmatter;

    return (
        <PageSection className={className} id={anchor}>
            {/* <Row className="text-center"> */}
            {/*    <Col className="service-item-col"> */}
            {/*        <button */}
            {/*            type="button" */}
            {/*            onClick={() => { */}
            {/*                window.CC.showSettings(0); */}
            {/*            }} */}
            {/*        >Open Cookie Consent Window */}
            {/*        </button> */}
            {/*        <button */}
            {/*            type="button" */}
            {/*            onClick={() => { */}
            {/*                window.CC.accept('all'); */}
            {/*                window.CC.hide(); */}
            {/*            }} */}
            {/*        >Accept All Cookies */}
            {/*        </button> */}
            {/*    </Col> */}
            {/* </Row> */}
            <Row className="text-center">
                {services.map((service) => (
                    <Col md={6} key={service.header} className="service-item-col">
                        <ServiceItem {...service} />
                    </Col>
                ))}
            </Row>
        </PageSection>
    );
};

Services.propTypes = {
    className: PropTypes.string,
    frontmatter: PropTypes.object,
};

Services.defaultProps = {
    className: null,
    frontmatter: null,
};

export default Services;
