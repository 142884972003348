import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Image from 'components/Image';

import './SpotItem.scss';

const SpotItem = ({
                      invert,
                      imageFileName,
                      imageAlt,
                      imageContent,
                      header,
                      subheader,
                      content,
                      maxDepth,
                      depthLabel,
                      meterLabel,
                      wrecks
                  }) => {
    const headerPart = header ? <h4>{header}</h4> : null;
    const depthPart = maxDepth ? <span className="spot-note"> – {depthLabel} {maxDepth} {meterLabel}</span> : null;
    const subheaderPart = subheader
        ? <h4 className="subheading">{subheader}{depthPart}</h4>
        : null;

    const liClassName = clsx('spot-item', {'spot-inverted': invert});

    return (
        <li className={liClassName}>
            <div className="spot-image">
                {imageContent || (
                    <Image
                        className="rounded-circle img-fluid"
                        fileName={imageFileName}
                        alt={imageAlt || header || subheader}
                    />
                )}
            </div>
            <div className="spot-panel">
                <div className="spot-heading">
                    {headerPart}
                    {subheaderPart}
                </div>
                <div className="spot-body">
                    <p className="text-muted">{content}</p>
                    {wrecks && <div className="spot-wreck-list">
                        {wrecks.map((wreck, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <div key={index} className="spot-wreck">
                                <div>
                                    <h4><a href={wreck.targetUrl} target="_blank" rel="noreferrer">{wreck.header}</a></h4>
                                    <span>{wreck.shipType} – {depthLabel} {wreck.maxDepth}m</span>
                                </div>
                                <Image
                                    className="wreck-image"
                                    fileName={wreck.imageFileName}
                                    alt={wreck.header}
                                />
                            </div>
                        ))}
                    </div>}
                </div>
            </div>
        </li>
    );
};

SpotItem.propTypes = {
    invert: PropTypes.bool,
    imageFileName: PropTypes.string,
    imageAlt: PropTypes.string,
    imageContent: PropTypes.any,
    header: PropTypes.string,
    subheader: PropTypes.string,
    content: PropTypes.string,
    maxDepth: PropTypes.number,
    depthLabel: PropTypes.string,
    meterLabel: PropTypes.string,
    wrecks: PropTypes.array,
};

SpotItem.defaultProps = {
    invert: false,
    imageFileName: '',
    imageAlt: '',
    imageContent: null,
    header: '',
    subheader: '',
    content: '',
    maxDepth: null,
    depthLabel: 'depth',
    meterLabel: 'meter',
    wrecks: null,
};

export default SpotItem;
