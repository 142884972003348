import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {Container, Card} from 'react-bootstrap';
import Image from 'components/Image';

import './ImageCard.scss';

const ImageCard = ({className, imageFileName, imageAlt, header, subheader, extraInfo}) => {
    return (
        <Card className={clsx('image-card bg-dark text-white text-center', className)}>
            <Image className="image" fileName={imageFileName} alt={imageAlt || header || subheader}/>
            <Card.ImgOverlay className="no-padding">
                <Container>
                    <div className="intro-text">
                        <div className="intro-lead-in">{subheader}</div>
                        <div className="intro-heading text-uppercase">{header}</div>
                        <div className="awards">
                            <a href="https://www.tripadvisor.com/Attraction_Review-g189117-d20906805-Reviews-WeDive-Lagos_Faro_District_Algarve.html"
                               target="_blank" rel="noreferrer">
                                <Image fileName="logos/tripadvisor_2022.png" alt="Tripadvisor Traveler's Choice Award 2022"/>
                            </a>
                            <a href="https://www.tripadvisor.com/Attraction_Review-g189117-d20906805-Reviews-WeDive-Lagos_Faro_District_Algarve.html"
                               target="_blank" rel="noreferrer">
                                <Image fileName="logos/tripadvisor_2023.png" alt="Tripadvisor Traveler's Choice Award 2023"/>
                            </a>
                        </div>
                        {/* <div className="intro-hashtag">#wearessi</div> */}
                        {extraInfo}
                    </div>
                </Container>
            </Card.ImgOverlay>
        </Card>
    );
};

ImageCard.propTypes = {
    className: PropTypes.string,
    imageFileName: PropTypes.string,
    imageAlt: PropTypes.string,
    header: PropTypes.string,
    subheader: PropTypes.string,
    extraInfo: PropTypes.any,
};

ImageCard.defaultProps = {
    className: null,
    imageFileName: null,
    imageAlt: null,
    header: '',
    subheader: '',
    extraInfo: null,
};

export default ImageCard;
