import React from 'react';
import PropTypes from 'prop-types';

import {Modal, Button} from 'react-bootstrap';
import Image from 'components/Image';

const CourseDetailDialog = ({
                                onHide,
                                imageFileName,
                                imageAlt,
                                header,
                                subheader,
                                content,
                                courseData,
                                globals,
                                ...restProps
                            }) => {
    return (
        <Modal
            {...restProps}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">{header}</Modal.Title>
            </Modal.Header>
            <Image
                className="img-fluid d-block"
                fileName={imageFileName}
                style={{maxHeight: 300}}
                alt={imageAlt || header || subheader}
            />
            <Modal.Body className="mx-auto">
                <p>{content}</p>
                {courseData && <div className="alert alert-warning" role="alert">{globals.courseContains}</div>}
                <br />
                {courseData}
            </Modal.Body>
            <Modal.Footer>
                <div className="mx-auto">
                    <Button variant="primary" onClick={onHide}>{globals.buttonClose}</Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

CourseDetailDialog.propTypes = {
    onHide: PropTypes.func,
    imageFileName: PropTypes.string,
    imageAlt: PropTypes.string,
    header: PropTypes.string,
    subheader: PropTypes.string,
    content: PropTypes.string,
    courseData: PropTypes.any,
    globals: PropTypes.object,
};

CourseDetailDialog.defaultProps = {
    onHide: null,
    imageFileName: '',
    imageAlt: null,
    header: '',
    subheader: '',
    content: '',
    courseData: null,
    globals: null,
};

export default CourseDetailDialog;
