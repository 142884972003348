import React from 'react';
import PropTypes from 'prop-types';

import {Container, Row, Col} from 'react-bootstrap';
import Imprint from 'components/Imprint';

const Footer = ({frontmatter, globals, location}) => {
    const [showImprint, setShowImprint] = React.useState(location && location.hash && location.hash === '#imprint');
    const [showPolicy, setShowPolicy] = React.useState(location && location.hash && location.hash === '#policy');

    const handleShowImprint = React.useCallback(() => {
        setShowImprint(true);
    }, []);
    const handleHideImprint = React.useCallback(() => {
        setShowImprint(false);
    }, []);

    const handleShowPolicy = React.useCallback(() => {
        setShowPolicy(true);
    }, []);
    const handleHidePolicy = React.useCallback(() => {
        setShowPolicy(false);
    }, []);

    if (!frontmatter) {
        return null;
    }

    const {
        copyright,
        privacyText,
        privacyContent,
        cancellationPolicyText,
        cancellationPolicyContent,
        termsHref,
        termsText
    } = frontmatter;
    const {buttonClose} = globals;

    return (
        <>
            <footer className="footer py-3">
                <Container>
                    <Row className="align-items-center text-center">
                        <Col lg={3} className="text-lg-left">
                            {copyright} ©2021-{new Date().getFullYear().toString().substring(2)}
                        </Col>
                        <Col lg={6} className="my-3 my-lg-0">
                            <a className="mr-3 text-nowrap" href="#imprint" data-toggle="modal"
                               onClick={handleShowImprint}>
                                {privacyText}
                            </a>
                            <a className="mr-3 text-nowrap" href="#policy" data-toggle="modal"
                               onClick={handleShowPolicy}>
                                {cancellationPolicyText}
                            </a>
                            <a className="text-nowrap" href={termsHref}>{termsText}</a>
                        </Col>
                    </Row>
                </Container>
            </footer>
            <Imprint
                show={showImprint}
                onHide={handleHideImprint}
                header={privacyText}
                content={privacyContent}
                buttonClose={buttonClose}
            />
            <Imprint
                show={showPolicy}
                onHide={handleHidePolicy}
                header={cancellationPolicyText}
                content={cancellationPolicyContent}
                buttonClose={buttonClose}
            />
        </>
    );
};

Footer.propTypes = {
    frontmatter: PropTypes.object,
    globals: PropTypes.object,
    location: PropTypes.object,
};

Footer.defaultProps = {
    frontmatter: null,
    globals: null,
    location: null,
};

export default Footer;
