import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {Row, Col} from 'react-bootstrap';
import PageSection from 'components/PageSection';
import Partner from 'components/Partner';

import FitchaDocument from '../../../../content/assets/documents/ficha_projeto_70630.pdf';

const Partners = ({className, frontmatter, globals}) => {
    if (!frontmatter) {
        return null;
    }

    const {anchor, partners} = frontmatter;

    return (
        <PageSection className={clsx('py-5', className)} id={anchor}>
            <Row style={{justifyContent: 'center'}}>
                {partners.map(({href, imageFileName}) => {
                    if (href !== 'project') {
                        return (
                            <Col lg={2} md={4} sm={4} xs={4} className="my-3" key={imageFileName}>
                                <Partner href={href} imageFileName={imageFileName}/>
                            </Col>
                        );
                    }
                    return null;
                })}
            </Row>
            {globals.financedBy && (<>
                <Row style={{justifyContent: 'center'}}>{globals.financedBy}</Row>
                <Row style={{justifyContent: 'center'}}>
                    {partners.map(({href, imageFileName}) => {
                        if (href === 'project') {
                            return (
                                <Col lg={2} md={4} sm={4} xs={4} className="my-3" key={imageFileName}>
                                    <Partner href={FitchaDocument} imageFileName={imageFileName}/>
                                </Col>
                            );
                        }
                        return null;
                    })}
                </Row>
            </>)}
        </PageSection>
    );
};

Partners.propTypes = {
    className: PropTypes.string,
    frontmatter: PropTypes.object,
    globals: PropTypes.object,
};

Partners.defaultProps = {
    className: null,
    frontmatter: null,
    globals: null,
};

export default Partners;
