import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {Row} from 'react-bootstrap';

import SectionHeader from 'components/SectionHeader';
import CourseItem from 'components/CourseItem';
import PageSection from 'components/PageSection';
import FareHaborItemLink from 'components/FareHaborItemLink';
import nl2br from 'utils/nl2br';
import './Courses.scss';

const Courses = ({className, frontmatter, globals, location}) => {
    if (!frontmatter) {
        return null;
    }

    const {anchor, anchorText, header: rootHeader, subheader: rootSubHeader, courses} = frontmatter;

    return (
        <PageSection className={clsx('course-section', className)} id={anchor}>
            <Row>
                <SectionHeader header={rootHeader} subheader={rootSubHeader}/>
            </Row>
            <Row>
                {courses.map(
                    ({content, courseData, header, imageFileName, imageFileNameDetail, subheader}) => (
                        <CourseItem
                            key={header}
                            imageFileName={imageFileName}
                            header={header}
                            subheader={subheader}
                            content={content}
                            globals={globals}
                            location={location}
                            imageFileNameDetail={imageFileNameDetail}
                            courseData={courseData && courseData.length ? (
                                <table className="table table-hover">
                                    <thead className="thead-light">
                                    <tr>
                                        <th scope="col">{anchorText}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {courseData.map((c) => {
                                        return (
                                            <tr key={c.title}>
                                                <td>
                                                    <div className="course-container">
                                                        <div className="course-description">
                                                            <h5 style={{color: 'var(--primary)'}}>{c.title}</h5>
                                                            <p dangerouslySetInnerHTML={{__html: nl2br(c.description)}}/>
                                                            {c.courseMinimumAge && <dl>
                                                                <dt>{globals.courseMinimumAge}</dt>
                                                                <dd>{c.courseMinimumAge}</dd>
                                                            </dl>}
                                                            {c.coursePrerequisites && <dl>
                                                                <dt>{globals.coursePrerequisites}</dt>
                                                                <dd>{c.coursePrerequisites}</dd>
                                                            </dl>}
                                                            {c.courseAcademicSessions && <dl>
                                                                <dt>{globals.courseAcademicSessions}</dt>
                                                                <dd>{c.courseAcademicSessions}</dd>
                                                            </dl>}
                                                            {c.coursePoolSessions && <dl>
                                                                <dt>{globals.coursePoolSessions}</dt>
                                                                <dd>{c.coursePoolSessions}</dd>
                                                            </dl>}
                                                            {c.courseDives && <dl>
                                                                <dt>{globals.courseDives}</dt>
                                                                <dd>{c.courseDives}</dd>
                                                            </dl>}
                                                        </div>
                                                        <div className="course-pricing">
                                                            {c.price &&
                                                            <h2 style={{whiteSpace: 'nowrap'}}>{c.price}</h2>}
                                                            {c.fareHabor && <FareHaborItemLink itemId={c.fareHabor}/>}
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    </tbody>
                                </table>
                            ) : null}
                        />
                    )
                )}
            </Row>
        </PageSection>
    );
};

Courses.propTypes = {
    className: PropTypes.string,
    frontmatter: PropTypes.object,
    globals: PropTypes.object,
    location: PropTypes.object,
};

Courses.defaultProps = {
    className: null,
    frontmatter: null,
    globals: null,
    location: null,
};

export default Courses;
