import React from 'react';
import PropTypes from 'prop-types';

import './WhatsAppButton.scss';

const WhatsAppButton = ({number}) => (
    <div className="joinchat joinchat--left joinchat--show joinchat--tooltip"
         role="button"
         tabIndex={-1}
         onClick={() => {
             window.location.href = `https://api.whatsapp.com/send?phone=${number}&mobile_only=false`;
         }}>
        <div className="joinchat__button">
            <div className="joinchat__button__open"/>
            <div className="joinchat__button__sendtext">Open chat</div>
        </div>
        <svg height="0" width="0">
            <defs>
                <clipPath id="joinchat__message__peak">
                    <path
                        d="M17 25V0C17 12.877 6.082 14.9 1.031 15.91c-1.559.31-1.179 2.272.004 2.272C9.609 18.182 17 18.088 17 25z"/>
                </clipPath>
            </defs>
        </svg>
    </div>
);

WhatsAppButton.propTypes = {
    number: PropTypes.string.isRequired
};

export default WhatsAppButton;
