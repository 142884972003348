import React from 'react';
import PropTypes from 'prop-types';

import ImageCard from 'components/ImageCard';
import VideoBackground from 'components/VideoBackground';
import FareHarborButton from 'components/FareHarborButton';
import WhatsAppButton from 'components/WhatsAppButton';
import Image from 'components/Image';
import HotNews from 'components/HotNews';

const Top = ({frontmatter, globals}) => {
    if (!frontmatter) {
        return null;
    }

    const {header, subheader, imageFileName, jumpToAnchor, 
        jumpToAnchorText, fareHarbor, hotNewsHeader, hotNewsContent} = frontmatter;
    const {buttonClose} = globals;

    let extraInfoPart;
    if (jumpToAnchor && jumpToAnchorText) {
        extraInfoPart = (
            <div className="logo-container">
                <Image className="image" fileName="logos/diamond-instructor-center.png" alt="SSI Diamond Instructor Center"/>
                <Image className="image" fileName="logos/xr-center.png" alt="SSI Extended Range Center"/>
                <Image className="image" fileName="logos/tdi.png" alt="TDI Dive Center"/>
                <Image className="image" fileName="logos/padi.png" alt="PADI Dive Center"/>
                <Image className="image" fileName="logos/dan-europe.png" alt="Dan Europe"/>
            </div>
        );
    }

    return (
        <>
            <FareHarborButton label={fareHarbor}/>
            <WhatsAppButton number="351916959233"/>
            <VideoBackground/>
            <ImageCard
                className="videoBackgroundOverlay"
                imageFileName={imageFileName}
                header={header}
                subheader={subheader}
                extraInfo={extraInfoPart}
            />
            {/* <HotNews */}
            {/*    header={hotNewsHeader} */}
            {/*    content={hotNewsContent} */}
            {/*    buttonClose={buttonClose} */}
            {/* /> */}
        </>
    );
};

Top.propTypes = {
    frontmatter: PropTypes.object,
    globals: PropTypes.object,
};

Top.defaultProps = {
    frontmatter: null,
    globals: null,
};

export default Top;
