import React from 'react';
import PropTypes from 'prop-types';
import {graphql} from 'gatsby';

import Navbar from 'views/Navbar';
import Top from 'views/Top';
import Footer from 'views/Footer';
import * as Sections from 'views/Sections';
import SEO from 'components/SEO';
import LanguageSelector from 'components/LanguageSelector';

import 'utils/fixFontAwesome';
import breakDownAllNodes from 'utils/breakDownAllNodes';
import fileNameToSectionName from 'utils/fileNameToSectionName';
// import CookieConsentComponent from '../cookies/CookieConsentComponent';

import '../style/main.scss';

/**
 * get file name list from content/sections folder
 */
export const query = graphql`
  query IndexQuery($langKey: String!) {
    site {
      siteMetadata {
        keywords
        description
      }
    }
    allMarkdownRemark(
      filter: { fields: { langKey: { eq: $langKey } } }
      sort: { order: ASC, fields: [fields___directoryName, fields___fileName] }
    ) {
      nodes {
        frontmatter {
          brand
          anchor
          anchorText
          partners {
            href
            imageFileName
          }
          content
          copyright
          header
          email
          imageFileName
          jumpToAnchor
          jumpToAnchorText
          fareHarbor
          hotNewsHeader
          hotNewsContent
          buttonClose
          buttonBookDives
          courseContains
          courseMinimumAge
          coursePrerequisites
          courseAcademicSessions
          coursePoolSessions
          courseDives
          depth
          meter
          financedBy
          menuText
          courses {
            content
            courseData {
              title
              description
              price
              fareHabor
              courseMinimumAge
              coursePrerequisites
              courseAcademicSessions
              coursePoolSessions
              courseDives
            }
            header
            subheader
            imageFileNameDetail
            imageFileName
          }
          privacyText
          privacyContent
          cancellationPolicyText
          cancellationPolicyContent
          services {
            content
            header
            iconName
            imageFileName
            jumpToAnchor
            jumpToAnchorIcon
            jumpToAnchorText
          }
          social {
            facebook
            github
            linkedin
            medium
            twitter
            instagram
            youtube
            tripadvisor
          }
          subheader
          teamMember {
            header
            imageFileName
            social {
              facebook
              github
              linkedin
              medium
              twitter
              instagram
              youtube
            }
            subheader
            disabled
          }
          address
          telephone
          termsHref
          termsText
          title
          spots {
            content
            header
            imageContent
            imageFileName
            subheader
            maxDepth
            wrecks {
              imageFileName
              header
              shipType
              targetUrl
              maxDepth
            }
          }
        }
        fields {
          fileName
          directoryName
        }
      }
    }
  }
`;

const IndexPage = ({location, data, pathContext: {langKey, defaultLang, langTextMap}}) => {
    const {
        site: {
            siteMetadata: {keywords, description},
        },
        allMarkdownRemark: {nodes},
    } = data;

    const {globalsNode, topNode, navBarNode, anchors, footerNode, sectionsNodes, trackingId} = breakDownAllNodes(nodes);

    let langSelectorPart;
    if (langTextMap != null && Object.keys(langTextMap).length > 1) {
        langSelectorPart = (
            <LanguageSelector langKey={langKey} defaultLang={defaultLang} langTextMap={langTextMap}/>
        );
    }

    return (
        <>
            <SEO lang={langKey} title="Diving the Algarve" keywords={keywords} description={description}/>
            <Navbar
                anchors={anchors}
                frontmatter={navBarNode.frontmatter}
                extraItems={langSelectorPart}
                globals={globalsNode.frontmatter}
            />
            <Top frontmatter={topNode.frontmatter} globals={globalsNode.frontmatter}/>
            {
                // dynamically import sections
                sectionsNodes.map(({frontmatter, fields: {fileName}}, ind) => {
                    const sectionComponentName = fileNameToSectionName(fileName);
                    const SectionComponent = Sections[sectionComponentName];

                    return SectionComponent ? (
                        <SectionComponent
                            key={sectionComponentName}
                            className={ind % 2 === 1 ? 'bg-light' : null}
                            frontmatter={frontmatter}
                            globals={globalsNode.frontmatter}
                            location={location}
                        />
                    ) : null;
                })
            }
            <Footer frontmatter={footerNode.frontmatter} globals={globalsNode.frontmatter} location={location}/>
            {/* <CookieConsentComponent/> */}
        </>
    );
};

IndexPage.propTypes = {
    location: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    pathContext: PropTypes.object,
};

IndexPage.defaultProps = {
    pathContext: {
        langKey: 'pt',
        defaultLang: 'pt',
        langTextMap: {},
    },
};

export default IndexPage;
