import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'react-bootstrap';
import Icon from 'components/Icon';

const FareHarborItemLink = ({label, iconName, itemId}) => {
    const dt = new Date();
    const month = `${dt.getMonth() + 1}`;

    return (
        <Button variant="secondary" size="sm" style={{whiteSpace: 'nowrap'}} onClick={() => {
            window.open(
                `https://fareharbor.com/embeds/book/wedive/items/${itemId}/calendar/${dt.getFullYear()}/${'00'.substring(0, 2 - month.length) + month}/?full-items=yes`,
                '_blank'
            );
        }}>
            <Icon iconName={iconName}/> {label}
        </Button>
    );
};

FareHarborItemLink.propTypes = {
    label: PropTypes.string,
    iconName: PropTypes.string,
    itemId: PropTypes.string.isRequired,
};

FareHarborItemLink.defaultProps = {
    label: 'book',
    iconName: 'ShoppingCartIcon'
};

export default FareHarborItemLink;
