import React, {useRef, useEffect} from 'react';

import videoFile from '../../content/assets/videos/background.mp4';
import './VideoBackground.scss';

const VideoBackground = () => {
    const ref = useRef(null);

    useEffect(() => {
            ref.current.play().catch((e) => {
                // happens on Safari after initial load....
            });
        },
        [ref]
    );

    return (
        <div id="videoBackground">
            <video ref={ref} preload={true} playsInline={true} muted={true} autoPlay={true} style={{backgroundColor: 'var(--gray)'}}>
                <source src={videoFile} type="video/mp4"/>
            </video>
        </div>
    );
};

export default VideoBackground;
