import React from 'react';
import PropTypes from 'prop-types';

import {Row, Col} from 'react-bootstrap';
import Icon from 'components/Icon';
import PageSection from 'components/PageSection';
import * as SocialIcons from 'components/SocialIcons';
import nl2br from 'utils/nl2br';

import './Contacts.scss';

const Contact = ({className, frontmatter}) => {
    if (!frontmatter) {
        return null;
    }

    const {
        anchor,
        header,
        subheader,
        telephone,
        email,
        address,
        social: {facebook, instagram, youtube, tripadvisor}
    } = frontmatter;

    return (
        <PageSection className={className} id={anchor}>
            <Row className="justify-content-center">
                <Col lg={8} className="text-center">
                    <h2 className="mt-0">{header}</h2>
                    <hr className="divider my-4"/>
                    <p className="text-muted mb-5">{subheader}</p>
                </Col>
            </Row>
            <Row className="contacts">
                <Col lg={12} className="mr-auto text-center">
                    {tripadvisor ? <SocialIcons.TripAdvisor attraction={tripadvisor}/> : null}
                    {facebook ? <SocialIcons.Facebook userName={facebook}/> : null}
                    {instagram ? <SocialIcons.Instagram userName={instagram}/> : null}
                    {youtube ? <SocialIcons.YouTube userName={youtube}/> : null}
                </Col>
            </Row>
            <Row className="contacts">
                <Col lg={4} className="ml-auto text-center">
                    <Icon iconName="PhoneIcon" size="3x" className="text-muted mb-3"/>
                    <a className="d-block" href={`tel:${telephone}`}>
                        {telephone}
                    </a>
                </Col>
                <Col lg={4} className="mr-auto text-center">
                    <Icon iconName="MapMarkerIcon" size="3x" className="text-muted mb-3"/>
                    <a href="https://www.google.com/maps/place/WeDive-Lagos/@37.1113082,-8.6746774,17z/data=!4m6!3m5!1s0xd1b31c777984f03:0xe564d82d78a3625d!8m2!3d37.1117033!4d-8.6752168!16s%2Fg%2F11j37snm0y"
                       target="_blank" rel="noreferrer">
                        <div dangerouslySetInnerHTML={{__html: nl2br(address)}}/>
                    </a>
                </Col>
                <Col lg={4} className="mr-auto text-center">
                    <Icon iconName="EnvelopIcon" size="3x" className="text-muted mb-3"/>
                    <a className="d-block" href={`mailto:${email}`}>
                        {email}
                    </a>
                </Col>
            </Row>
        </PageSection>
    );
};

Contact.propTypes = {
    className: PropTypes.string,
    frontmatter: PropTypes.object,
};

Contact.defaultProps = {
    className: null,
    frontmatter: null,
};

export default Contact;
