import React from 'react';
import PropTypes from 'prop-types';

import './FareHarborButton.scss';

const FareHarborButton = ({label, bookingItems}) => (
    <a
        href={`https://fareharbor.com/embeds/book/wedive/items/?flow=435035${bookingItems ? '' : '&full-items=yes'}`}
        className="fare-harbor"
        target="_blank" rel="noreferrer">
        {label}
    </a>
);

FareHarborButton.propTypes = {
    label: PropTypes.string.isRequired,
    bookingItems: PropTypes.string,
};

FareHarborButton.defaultProps = {
    bookingItems: null
}

export default FareHarborButton;
