import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'react-bootstrap';

import './ServiceItem.scss';
import useSmoothScrollTo from 'hooks/useSmoothScrollTo';
import Icon from 'components/Icon';

const ServiceItem = ({header, content, jumpToAnchor, jumpToAnchorIcon, jumpToAnchorText}) => {
    const scrollToSection = useSmoothScrollTo(jumpToAnchor);
    return (
        <>
            <h4 className="service-item-heading">{header}</h4>
            <p className="text-muted service-item-content">{content}</p>
            {jumpToAnchor !== '' && (<Button size="lg" variant="secondary" onClick={scrollToSection}>
                <Icon iconName={jumpToAnchorIcon}/> {jumpToAnchorText}
            </Button>)}
        </>
    );
};

ServiceItem.propTypes = {
    header: PropTypes.string,
    content: PropTypes.string,
    jumpToAnchor: PropTypes.string,
    jumpToAnchorIcon: PropTypes.string,
    jumpToAnchorText: PropTypes.string
};

ServiceItem.defaultProps = {
    header: '',
    content: '',
    jumpToAnchor: '',
    jumpToAnchorIcon: 'InfoIcon',
    jumpToAnchorText: ''
};

export default ServiceItem;
