import React from 'react';
import PropTypes from 'prop-types';

import {Modal, Button} from 'react-bootstrap';

const Imprint = ({
                     onHide,
                     header,
                     content,
                     buttonClose,
                     ...restProps
                 }) => {
    return (
        <Modal
            {...restProps}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">{header}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="mx-auto" dangerouslySetInnerHTML={{__html: content}}/>
            <Modal.Footer>
                <div className="mx-auto">
                    <Button variant="primary" onClick={onHide}>{buttonClose}</Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

Imprint.propTypes = {
    onHide: PropTypes.func,
    header: PropTypes.string,
    content: PropTypes.string,
    buttonClose: PropTypes.string,
};

Imprint.defaultProps = {
    onHide: null,
    header: 'Imprint',
    content: 'foo',
    buttonClose: 'bar',
};

export default Imprint;
